import { sharedEntities } from 'shared/types.ts';
import { TwContainer } from '@/lib/components/TwContainer.tsx';
import { useDisclosure } from '@mantine/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, Checkbox, Flex, MultiSelect, TextInput } from '@mantine/core';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import { api } from '@/lib/connectivity.ts';
import { enumToPair, showNotification } from '@/lib/utility.ts';
import { Form, useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';
import TextEditor from '@/lib/components/TextEditor/TextEditor.tsx';
import { ErrorTypes, SiteGroup, TemplateLabel } from 'shared/enums.ts';
import { useEssentialStore } from '@/pages/essential.store.tsx';
import { Editor } from '@tiptap/react';
import PopupConfirmation from '@/components/PopupConfirmation.tsx';
import { einEntityTypes } from 'shared/consts.ts';
import AppCombo from '@/lib/components/AppCombo.tsx';

const EmailTemplates = ({ group }: { group: SiteGroup }) => {
	const [bodyEditor, setBodyEditor] = useState<Editor>();
	const essentialStore = useEssentialStore();
	const [template, setTemplate] = useState<sharedEntities.EmailTemplateEntity | null>(null);
	const [loading, { open: showLoading, close: hideLoading }] = useDisclosure();
	const form = useForm<sharedEntities.EmailTemplateEntity>({
		initialValues: {
			identifier: '',
			_id: '',
			body: '',
			entities: [],
			labels: [],
			showOrderDetails: true,
			subject: '',
			title: '',
			type: '',
			subLabels: [],
			sites: [],
		},
		validateInputOnBlur: true,
		validateInputOnChange: true,
		clearInputErrorOnChange: true,
		validate: zodResolver(
			z.object<Partial<sharedEntities.EmailTemplateEntity>>({
				identifier: z.string().min(1, { message: 'Identifier is required' }),
				subject: z.string().min(1, { message: 'Subject is required' }),
				title: z.string().min(1, { message: 'Title is required' }),
				body: z.string().min(1, { message: 'Body is required' }),
			}),
		),
	});
	useEffect(() => {
		form.setValues({
			identifier: template?.identifier || '',
			_id: template?._id || '',
			body: template?.body || '',
			entities: template?.entities || [],
			labels: template?.labels || [],
			showOrderDetails: template?.showOrderDetails || true,
			subject: template?.subject || '',
			title: template?.title || '',
			type: template?.type || '',
			subLabels: template?.subLabels || [],
			sites: template?.sites || [],
		});
		bodyEditor?.commands.setContent(template?.body || '');
	}, [template]);
	const save = (values: sharedEntities.EmailTemplateEntity) => {
		showLoading();
		api
			.post(`settings/template/email/${group}`, {
				...values,
			})
			.then(({ data }) => {
				if (data.isSuccess && !template) {
					essentialStore.fetchEmailTemplates(group).finally(() => {
						setTemplate(data.body);
					});
				}
				showNotification({ data });
			})
			.finally(hideLoading);
	};
	const appliedFor = useMemo<Array<TemplateLabel>>(() => form.values.labels, [form.values.labels]);

	const isEinTemplate = useCallback((val: TemplateLabel) => (val > 5000 && val < 6000) || group == SiteGroup.EIN, [group]);
	const einTemplates = useMemo(() => appliedFor.filter((item) => isEinTemplate(item)), [appliedFor]);
	const isMixing = useMemo(() => einTemplates.length > 0 && einTemplates.length < appliedFor.length, [appliedFor, einTemplates]);
	useEffect(() => {
		if (isMixing) {
			form.setErrors({ labels: 'EIN Template cannot be mixed with other templates' });
		}
	}, [isMixing]);

	const deleteTemplate = (id: string) => {
		showLoading();
		api
			.delete(`settings/template/email/${id}`)
			.then(({ data }) => {
				if (data.isSuccess) {
					essentialStore.fetchEmailTemplates(group).finally(() => {
						setTemplate(null);
					});
				}
				showNotification({ data });
			})
			.finally(hideLoading);
	};

	const allStates = useMemo(() => {
		const results = [];
		if (group === SiteGroup.AnnualReport) {
			results.push(
				...essentialStore.AnnualReport.map((state) => ({
					disabled: isEinTemplate(appliedFor[appliedFor.length - 1]),
					value: state.abbreviation,
					label: state.name,
				})),
			);
		}
		if (group === SiteGroup.Fictitious) {
			results.push(
				...essentialStore.Fictitious.map((state) => ({
					disabled: isEinTemplate(appliedFor[appliedFor.length - 1]),
					value: state.abbreviation,
					label: state.name,
				})),
			);
		}
		if (group === SiteGroup.LaborLaw) {
			results.push(
				...essentialStore.LaborLaw.map((state) => ({
					disabled: isEinTemplate(appliedFor[appliedFor.length - 1]),
					value: state.abbreviation,
					label: state.name,
				})),
			);
		}
		if (group === SiteGroup.PropertyDocument) {
			results.push(
				...essentialStore.PropertyDocument.map((state) => ({
					disabled: isEinTemplate(appliedFor[appliedFor.length - 1]),
					value: state.abbreviation,
					label: state.name,
				})),
			);
		}
		if ([SiteGroup.LaborLaw, SiteGroup.EIN].includes(group)) {
			results.push(
				...einEntityTypes.map((einType) => ({
					disabled: group !== SiteGroup.EIN && !isEinTemplate(appliedFor[appliedFor.length - 1]),
					value: einType.identifier,
					label: einType.name,
				})),
			);
		}

		return results;
	}, [appliedFor, essentialStore.LaborLaw, essentialStore.AnnualReport, essentialStore.Fictitious, essentialStore.PropertyDocument, group]);
	return (
		<TwContainer loading={loading}>
			<Flex
				columnGap={'xl'}
				rowGap={'md'}
				className={'tw-flex-col md:tw-flex-row '}>
				<AppCombo<sharedEntities.EmailTemplateEntity>
					placeholder={'Select Email Template'}
					variant={'default'}
					value={template}
					searchable
					description={`${essentialStore.getEmailTemplatesOfGroup(group).length || 0} email templates`}
					onChange={(v) => {
						setTemplate(v);
					}}
					className={'tw-flex-grow'}
					data={essentialStore.getEmailTemplatesOfGroup(group)}
				/>
				<Button
					variant={'filled'}
					onClick={() => setTemplate(null)}
					leftSection={<IconPlus />}
					children={'New Template'}
				/>
			</Flex>
			<Form
				form={form}
				onSubmit={save}>
				<Card
					className={'tw-grid tw-gap-4 tw-mt-4'}
					variant={'outline'}>
					<TextInput
						label={'Identifier'}
						withAsterisk
						{...form.getInputProps('identifier')}
					/>
					<MultiSelect
						clearable
						searchable
						data={essentialStore.websitesByGroup(group).map((site) => ({
							value: site._id,
							label: `${site.name} [${site.short}] [Web:${site.web}]`,
						}))}
						placeholder={'All Selected'}
						label={'Sites'}
						{...form.getInputProps('sites')}
					/>
					<AppCombo<TemplateLabel, true>
						multiple={true}
						label={'Applied For'}
						data={enumToPair(TemplateLabel, true).map((item) => ({
							...item,
							disabled: isMixing && (isEinTemplate(appliedFor[appliedFor.length - 1]) ? !isEinTemplate(item.value) : isEinTemplate(item.value)),
						}))}
						{...form.getInputProps('labels')}
						placeholder={'Select Applied For'}
						searchable
					/>
					{(!isMixing && einTemplates.findIndex((item) => item === TemplateLabel.AfterEinError) > -1) ||
						(group == SiteGroup.EIN && (
							<AppCombo<ErrorTypes, true>
								multiple={true}
								label={'Error Types'}
								data={enumToPair(ErrorTypes, true)}
								{...form.getInputProps('subLabels')}
							/>
						))}
					{![SiteGroup.BOI, SiteGroup.Certificate].includes(group) && (
						<MultiSelect
							searchable
							clearable
							data={allStates}
							placeholder={'All Selected'}
							label={!isMixing && einTemplates.length > 0 ? 'Affected Type' : 'Affected Collections'}
							{...form.getInputProps('entities')}
						/>
					)}
					<TextInput
						label={'Subject'}
						withAsterisk
						{...form.getInputProps('subject')}
					/>
					<TextInput
						label={'Title'}
						withAsterisk
						{...form.getInputProps('title')}
						styles={{
							section: {
								cursor: 'pointer',
								margin: 'auto',
								marginRight: 4,
								height: 26,
								width: 'auto',
								padding: 8,
								backgroundColor: 'white',
							},
						}}
						rightSection={
							<Checkbox
								className={'tw-cursor-pointer'}
								label={'Include Order Details'}
								{...form.getInputProps('showOrderDetails')}
							/>
						}
					/>
					<TextEditor
						wrapperProps={{
							label: 'Body',
							required: true,
							...form.getInputProps('body'),
						}}
						onEditorCreated={setBodyEditor}
						items={essentialStore.getGroupTemplateUserOptions(group)}
						{...form.getInputProps('body')}
					/>
					<Flex
						justify={'space-between'}
						className={'tw-col-span-full tw-pt-4'}>
						{form.values._id && (
							<PopupConfirmation
								onConfirmed={() => deleteTemplate(form.values._id)}
								position={'top-start'}>
								<Button
									type={'button'}
									variant={'outline'}
									color={'red'}>
									<IconTrash />
								</Button>
							</PopupConfirmation>
						)}
						<div />
						<Button type={'submit'}>Save</Button>
					</Flex>
				</Card>
			</Form>
		</TwContainer>
	);
};

export default EmailTemplates;
