export enum SiteGroup {
	LaborLaw = 'LaborLaw',
	AnnualReport = 'AnnualReport',
	Fictitious = 'Fictitious',
	PropertyDocument = 'PropertyDocument',
	Certificate = 'Certificate',
	EIN = 'EIN',
	BOI = 'BOI',
	YourKits = 'YourKits',
	EssentialDocument = 'EssentialDocument',
}

export enum ShippingMethod {
	Email = 'Email',
	Mail = 'Mail',
}

export enum ShowOptions {
	All = 'All',
	ReturnedOnly = 'ReturnedOnly',
	OneTimeOnly = 'OneTimeOnly',
	NewOnly = 'NewOnly',
}

export enum PaymentMethod {
	Cash = 'Cash',
	Check = 'Check',
	CreditCard = 'CreditCard',
	Paypal = 'Paypal',
	Other = 'Other',
	Blank = 'Blank',
	Zelle = 'Zelle',
	WireTransfer = 'WireTransfer',
}

export enum TwilioLogType {
	Fax = 'Fax',
	Sms = 'Sms',
	Voice = 'Voice',
	Mms = 'Mms',
	Unlisted = 'UNLISTED',
}

export enum PaymentMerchant {
	Payeezy = 'Payeezy',
	Stripe = 'Stripe',
	Authorize = 'Authorize',
	Paypal = 'Paypal',
	Clover = 'Clover',
	TwoCheckout = 'TwoCheckout',
	Square = 'Square',
}

export enum ProcessingFeeType {
	Flat = 'Flat',
	Percentage = 'Percentage',
}

export enum PaymentLabel {
	NewOrder = 'Order',
	Refund = 'Refund',
	Invoice = 'Invoice',
	Payment = 'Payment',
}

export enum OrderSource {
	Offline = 0,
	Online = 1,
}

export enum ProspectsCategory {
	ReturnOnly = -1,
	OneTimeOnly = 1,
	NewOnly = 0,
}

export enum OrderType {
	New = 1,
	ReOrder = 2,
}

export enum OrderStatus {
	Scheduled = 1,
	Queued = 2,
	Processing = 3,
	Hold = 4,
	Complete = 5,
	Cancelled = 6,
	Lost = 7,
	PendingRefund = 8,
	Refund = 9,
	Unknown = 0,
}

export enum ClaimMdStatus {
	Acknowledged = 'Acknowledged',
	Accepted = 'Accepted',
	Hold = 'Hold',
	Archived = 'Archived',
}

export enum ShipmentStatus {
	Queued = 'Queued',
	Processing = 'Processing',
	Shipped = 'Shipped',
	Back_Order = 'Back_Order',
	On_Hold = 'On_Hold',
	Canceled = 'Canceled',
	Error = 'Error',
}

export enum InsuranceEligibility {
	Eligible = 0,
	Hold = 1,
	Ineligible = 2,
}

export enum RefundTypes {
	ChargeBack = 'ChargeBack', //0
	Refund = 'Refund', //1
	StopPayment = 'StopPayment', //2
	Void = 'Void', //3
	Dispute = 'Dispute', //4
}

export enum YourKitsActions {
	OptIn = 1,
	OptOut = 2,
	Archive = 3,
	Cancel = 4,
}

export enum UpdateFlag {
	SAME = 0,
	EDITED = 1,
	NEW = 2,
	REMOVED = 3,
}

export enum TemplateLabel {
	AfterOnlineOrder = 1,
	AfterOfflineOrder = 2,
	AfterMarkAsCompleted = 3,
	AfterCreditCardRefund = 4,
	AfterMarkAsProcessing = 5,
	AfterMarkAsPending = 6,
	AfterMarkAsError = 7,
	AfterMarkAsIssue = 8,
	AfterMarkAsResend = 9,
	AfterMarkAsReturned = 10,

	AfterRefundRequest = 100,
	AfterChargeback = 101,
	AfterCheckRefund = 102,
	AfterStopPayment = 103,
	AfterPaypalRefund = 104,
	AfterPartialPaypalRefund = 105,
	AfterPartialCreditCardRefund = 106,
	AfterDispute = 107,
	InvoicePaymentComplete = 108,
	DeclinedPayment = 109,
	DeclinedPaymentDone = 110,
	InvoicePaymentRefund = 111,
	AfterCreditCheckRefund = 112,

	AffidavitProof = 501,
	ToFillOrderAdditionalInfo = 502,
	AfterInvoiceCreated = 503,

	AfterUnsubscription = 801,
	AfterCancellation = 802,
	OnIncomingCall = 850,

	At1stAlert = 1001,
	At2ndAlert = 1002,
	At3rdAlert = 1003,

	AfterCertificateCompleted = 3001, //3001-3999 is reserved for EIN

	AfterEinInfoSubmitted = 5001, //5001-5999 is reserved for EIN
	AfterEinCompleted = 5002,
	AfterEinError = 5003,
	SendEinLink = 5004,

	AfterBoiInfoSubmitted = 6001, //6001-6999 is reserved for BOI
	AfterBoiCompleted = 6002,
	AfterBoiError = 6003,
	SendBoiLink = 6004,

	StaffRefundRequestAlert = 10001,
	StaffDisputeRequestAlert = 10002,

	PortalSendOtp = 20001,
}

export enum Status {
	Entities = 'Entities',
	Mailings = 'Mailings',
	Order = 'Order',
	Issue = 'Issue',
	Processing = 'Processing',
	Pending = 'Pending',
	Completed = 'Completed',
	Refund = 'Refund',
	PartialRefund = 'PartialRefund',
	Error = 'Error',
	PaymentError = 'PaymentError',
	RefundRequested = 'RefundRequested',
	RefundRequestApproved = 'RefundRequestApproved',
	Returned = 'Returned',
	Resend = 'Resend',
	Expired = 'Expired',
	InfoSubmitted = 'InfoSubmitted',
	Deleted = 'Deleted',
	Moved = 'Moved',
	Alert = 'Alert',
	Unpaid = 'Unpaid',
	Payment = 'Payment',
	EmailPreference = 'EmailPreference',
	BotFailed = 'BotFailed',
	Merged = 'Merged',
	SentToBot = 'SentToBot',
	ResetFiling = 'ResetFiling',
	SubmitToFinCen = 'SubmitToFinCen',
	SaveProgress = 'SaveProgress',
}

export enum OldStatus {
	Originals = 'Originals',
	Prospects = 'Prospects',
	Orders = 'Orders',
	Issues = 'Issues',
	Processing = 'Processing',
	Pending = 'Pending',
	Completed = 'Completed',
	Refunds = 'Refunds',
	PartialRefunds = 'PartialRefunds',
	Error = 'Error',
	PaymentError = 'PaymentError',
	RefundRequested = 'RefundRequested',
	RefundRequestApproved = 'RefundRequestApproved',
	ReturnedPoster = 'ReturnedPoster',
	ResendPoster = 'ResendPoster',
	Expired = 'Expired',
}

export enum ReversalStatus {
	Pending = 'Pending',
	Completed = 'Completed',
}

export enum FieldTypes {
	Date = 'Date',
	Text = 'Text',
	Number = 'Number',
	Email = 'Email',
	Phone = 'Phone',
	Currency = 'Currency',
	File = 'File',
	Link = 'Link',
}

export enum AliasColumns {
	None = 'None',
	Company = 'Company',
	PreSortDate = 'PreSortDate',
	BusinessID = 'BusinessID',
	PropertyID = 'PropertyID',
	Reserved = 'Reserved',
}

export enum ErrorTypes {
	Error101 = 101,
	Error102 = 102,
	Error103 = 103,
	Error104 = 104,
	Error105 = 105,
	Error106 = 106,
	Error107 = 107,
	Error115 = 115,
}

export enum State {
	ACTIVE = 0,
	INACTIVE = 1,
	SUSPENDED = 2,
	INVITED = 3,
	APPLIED = 4,
	REGISTERED = 5,
	TERMINATED = 6,
	Completed = 7,
	Printing = 8,
}

export enum FileStatus {
	Deleted = -1,
	Uploaded = 0,
	InUse = 1,
}

export enum AddOnsAlias {
	English = 'English',
	Spanish = 'Spanish',
	Covid19 = 'Covid19',
	SpanishCovid19 = 'SpanishCovid19',
	EIN = 'EIN',
	Certificate = 'Certificate',
	LateFee = 'LateFee',
	BOI = 'BOI',
	AnnualReport = 'AnnualReport',
	Dissolution = 'Dissolution',
	Fictitious = 'Fictitious',
	PropertyDocument = 'PropertyDocument',
}

export enum AddOnServiceType {
	Basic = 'Basic',
	Premium = 'Premium',
}

export enum ProductCategory {
	RegularProduct = 'RegularProduct',
	CheckoutOnly = 'CheckoutOnly',
	Dissolution = 'Dissolution',
}

export enum YourKitsProductType {
	PackageInsert = 'PackageInsert',
	OtcTestKit = 'OtcTestKit',
}

export enum SortDirection {
	ASC = 1,
	DESC = -1,
}

export enum FilterSelection {
	Single = 1,
	Multiple = 2,
}

export enum TaskImportance {
	Normal = 'Normal',
	UrgentAndImportant = 'UrgentAndImportant',
	Important = 'Important',
	Urgent = 'Urgent',
}

export enum TaskState {
	ToDo = 'ToDo',
	Doing = 'Doing',
	PendingApproval = 'PendingApproval',
	Issue = 'Issue',
	Done = 'Done',
}

export enum TimeStationType {
	WorkDay = 'WorkDay',
	VacationDay = 'VacationDay',
	SickDay = 'SickDay',
}

export enum MaritalStatus {
	Single = 'Single',
	Married = 'Married',
	MarriedButWithhold = 'MarriedButWithhold',
	NotWithhold = 'NotWithhold',
}

export enum AnnualReportChanged {
	Pending = 'Pending',
	Yes = 'Yes',
	No = 'No',
}

export enum EmailFrequency {
	Subscribed = 'Subscribed',
	Weekly = 'Weekly',
	OrderUpdates = 'OrderUpdates',
	Unsubscribe = 'Unsubscribe',
}

export enum Frequency {
	PerWeek = 0,
	PerMonth = 1,
	PerYear = 2,
	Hourly = 3,
}

export enum PaymentType {
	Salary = 0,
	Hourly = 1,
}

export enum PayFrequency {
	EveryWeek = 0,
	EveryMonth = 1,
	EveryOtherWeek = 2,
	//ThriceAMonth
}

export enum ScheduleFrequency {
	Daily = 'Daily',
	Weekly = 'Weekly',
	BiWeekly = 'BiWeekly',
	Monthly = 'Monthly',
	Yearly = 'Yearly',
}

export enum PaymentKind {
	CASH = 0,
	CHECK = 1,
	CREDIT_CARD = 2,
	CREDIT_DEBIT = 3,
	OTHER = 4,
	BLANK = 5,
}

export enum IssueStatus {
	Open = 'Open',
	Resolved = 'Resolved',
}

export enum CommunicationType {
	Sms = 'Sms',
	Voice = 'Voice',
	Email = 'Email',
	Mail = 'Mail',
}

export enum NotifyOptions {
	Email = 'Email',
	SMS = 'SMS',
	Push = 'Push',
}

export enum Activities {
	Refresh = 0,
	Login = 10,
	Logout = 20,
	ClockIn = 30,
	ClockOut = 40,

	Delete = 50,
	DeleteAddOn = 51,
	DeleteTemplate = 52,
	Disable = 53,
	Edit = 60,
	EditAddOn = 61,
	EditUserRole = 62,
	New = 70,
	NewAddOn = 71,
	NewEmailTemplate = 72,
	NewSmsTemplate = 73,
	NewAudioTemplate = 74,
	Enable = 75,
	Add = 80,
	AddNote = 81,
	Find = 90,
	ViewList = 100,
	See = 110,
	Check = 120,
	Export = 130,
	Import = 140,
	Search = 150,
	AttachBank = 151,
	GenerateReversalCheck = 152,

	ExportProspects = 505,
	ExportOrders = 510,
	ExportCompleted = 515,
	ExportRefunds = 520,
	CheckDuplicate = 521,

	ReturnPoster = 525,

	DownloadInvoice = 530,
	DownloadCheck = 531,
	DownloadRefundInvoice = 535,
	DownloadCover = 540,
	DownloadForm = 545,

	AddNewMailing = 549,
	AddNewEntity = 550,
	NewTask = 551,
	Comment = 552,
	NewTaskAttachment = 553,
	CreateOrder = 555,
	MarkAsOrder = 560,
	MarkAsDoNotCharge = 561,
	MarkAsDoCharge = 562,
	MarkAsError = 565,
	MarkAsRefund = 570,
	MarkOrderAsCompleted = 575,
	MarkAsCompleted = 576,
	MarkAsIssue = 577,
	MarkAsMoved = 578,
	MarkAsProcessing = 580,
	MarkAsPrinting = 581,
	MarkAsActive = 582,
	MarkAsPending = 585,
	RollBackToOrder = 586,
	RollBackToCompleted = 587,
	AddNewAttachment = 590,
	AddNewAttachmentFromOrder = 595,
	AddNewAttachmentFromError = 596,
	AddNewAttachmentFromCompleted = 600,
	AddNewAttachmentFromRefund = 605,
	ModifyProcessingInfo = 606,

	SeeAnnualReportDashboard = 610,
	SeeActiveJobs = 611,
	SeeCompletedJobs = 612,
	SeeLaborLawDashboard = 615,
	SeeCertificateDashboard = 616,
	SeeFictitiousDashboard = 620,
	SeePropertyDocumentDashboard = 625,
	SeeEinDashboard = 630,
	SeeBOIDashboard = 631,
	SeeMailingsTable = 635,
	SeeEntityTable = 636,
	SeeProfile = 640,
	SeeDetails = 645,
	SeeTaskDetails = 646,
	SeeOrderTable = 650,
	SeeIssuesTable = 651,
	SeeInfoSubmittedTable = 652,
	SeeCompleteTable = 655,
	SeeRefundTable = 660,
	SeeErrorTable = 665,
	SeeProcessingTable = 670,
	SeePendingTable = 675,
	SeeInvoiceTable = 676,
	SeeAttachments = 680,

	EditEntity = 685,
	EditTask = 686,
	EditMailing = 687,
	EditOrder = 690,
	ChangeOrderStatus = 691,
	ChangeStatus = 692,
	EditCompleted = 695,
	EditRefund = 700,
	EditError = 705,
	EditProcessing = 710,
	EditPending = 715,
	EditNote = 720,
	MoveTransaction = 721,

	DeleteEntities = 725,
	DeleteMailings = 726,
	DeleteEntity = 730,
	DeleteMailing = 731,
	DeleteOrder = 735,
	DeleteRefund = 740,
	DeleteCompleted = 745,
	DeleteError = 750,
	DeleteAttachment = 755,

	MergeOrder = 4500,

	TryToRefund = 4550,

	ResetFilingInfo = 4600,

	RunBot = 4650,
	SubmitToFinCen = 4651,
	CheckFinCenStatus = 46521,
	RetrieveFinCenStatus = 4653,

	BlastTable = 5000,
	SendBulkEmail = 5001,
	LeadsTable = 5002,
	SendBulkSms = 5005,
	SendBulkVoiceMail = 5016,
	SendEmail = 5010,
	SendSms = 5015,
	SendVoiceMail = 5017,
	SendFax = 5020,
	SendAffidavit = 5025,

	ResendPoster = 5030,

	SeeProfitLossReport = 6000,
	SeeIndustryReport = 6001,
	SeeOrderReport = 6010,
	SeeOrderByDateReport = 6020,
	SeeOnlineReport = 6030,
	SeeOfflineReport = 6040,
	SeePosterReport = 6050,
	SeePMTReport = 6060,
	SeeConversionReport = 6070,
	SeeJobHistoryReport = 6080,
	SeeCostReport = 6090,
	SeeSmsReport = 6091,
	SeeCallsReport = 6092,

	InvoiceRefund = 7000,

	SeeRecentMessage = 8000,
	SeeCustomerMessage = 8001,
	SeeRecentUnlistedMessage = 8010,
	SeeCustomerUnlistedMessage = 8011,

	SetFeeNCost = 8200,

	SeeRecentVoiceCalls = 8500,
	SeeCustomerVoiceCalls = 8501,
	SeeRecentUnlistedVoiceCalls = 8510,
	SeeCustomerUnlistedVoiceCalls = 8511,

	ArchivePatient = 8700,
	ArchiveUnlistedPatient = 8701,
	Unarchive = 8702,
	CancelPatient = 8800,
	CancelAndArchivePatient = 8801,
	BulkCancelPatient = 8802,
	OptInAndArchivePatient = 8911,
	OptInPatient = 8912,
	OptOutPatient = 8913,
	OptOutAndArchivePatient = 8914,
	CancelAndArchiveAndTransmit = 8916,

	FetchFromApi = 9000,
	CheckNumberTypes = 9100,
	CheckInsuranceStatus = 9101,

	SaveKeyBindings = 9111,

	AddIssue = 9500,
	SolveIssue = 9501,

	SubmitClaim = 9600,
	ArchiveClaim = 9601,
	ResolveClaimHold = 9602,
}

export const generateReference = (group: SiteGroup, collection: string, prefix = 0) => {
	return (group.charAt(0) + (collection?.substring(0, 2) || '') + prefix + (+new Date()).toString(36)).toUpperCase();
};
